import {
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Radio,
  Row,
  Slider,
  Space,
} from 'antd';
import {
  DollarCircleFilled,
  DownOutlined,
  UndoOutlined,
  UpOutlined,
} from './Icons';
import React, { ReactElement, useEffect, useState, useRef } from 'react';
import { useMobxStores } from '@/stores';
import { observer } from 'mobx-react-lite';
import { isMdQuery } from '@/helpers/format';
import useOnclickOutside from 'react-cool-onclickoutside';
import { pushLayerEvent } from '@/utils/gtag';

interface ITelaData {
  showSortBy: boolean;
  sortBy: number;
  showRangePrice: boolean;
  rangePrice: [number, number];
  showHighlights: boolean;
  highlights: Array<string>;
  showGuestRating: boolean;
  guestRating: number;
}

const defaultTelaValues: ITelaData = {
  showSortBy: false,
  sortBy: 1,
  showRangePrice: false,
  rangePrice: [0, -1],
  showHighlights: false,
  highlights: [],
  showGuestRating: false,
  guestRating: 1,
};

function FilterFields(): ReactElement {
  const { searchStore } = useMobxStores();
  const [ui, uiSet] = useState<ITelaData>({ ...defaultTelaValues });

  function handleChangeSortBy(e) {
    uiSet({ ...ui, sortBy: e.target.value });
    if (!isMdQuery()) {
      searchStore.setSortBy(e.target.value);
    }
  }
  function applyChangeSortBy(e) {
    searchStore.setSortBy(ui.sortBy);
    uiSet({ ...ui, showSortBy: false });
  }
  const onCloseSortBy = (e) => {
    uiSet({ ...ui, showSortBy: false });
  };

  function handleChangePriceRange(value) {
    uiSet({ ...ui, rangePrice: value });
    if (!isMdQuery()) {
      searchStore.setFilterPriceRange(value);
    }
  }
  function applyChangePriceRange(value) {
    searchStore.setFilterPriceRange(ui.rangePrice);
    uiSet({ ...ui, showRangePrice: false });
  }

  const onClosePriceRange = (e) => {
    uiSet({ ...ui, showRangePrice: false });
  };

  function handleChangeHighlights(value) {
    uiSet({ ...ui, highlights: value });
    if (!isMdQuery()) {
      searchStore.setFilterHighlights(value);
    }
  }
  function applyChangeHighlights(value) {
    searchStore.setFilterHighlights(ui.highlights);
    uiSet({ ...ui, showHighlights: false });
  }
  const onCloseHighlights = (e) => {
    uiSet({ ...ui, showHighlights: false });
  };

  function handleChangeGuestRatings(e) {
    uiSet({ ...ui, guestRating: e.target.value });
    if (!isMdQuery()) {
      searchStore.setFilterGuestRating(e.target.value);
    }
  }

  function applyChangeGuestRatings(e) {
    searchStore.setFilterGuestRating(ui.guestRating);
    uiSet({ ...ui, showGuestRating: false });
  }
  const onCloseGuestRatings = (e) => {
    uiSet({ ...ui, showGuestRating: false });
  };

  function handleResetFilter() {
    uiSet({ ...defaultTelaValues, rangePrice: [0, searchStore.maxPrice + 4] });
    searchStore.resetFilter();
  }

  const refSort = useRef();
  const refRangePrice = useRef();
  const refHighlights = useRef();
  const refGuest = useRef();
  useOnclickOutside(
    () => {
      if (!isMdQuery()) {
        uiSet({
          ...ui,
          showSortBy: false,
          showRangePrice: false,
          showHighlights: false,
          showGuestRating: false,
        });
      }
    },

    { refs: [refSort, refRangePrice, refHighlights, refGuest] }
  );

  useEffect(() => {
    if (searchStore.maxPrice && -1 == ui.rangePrice[1]) {
      uiSet({ ...ui, rangePrice: [0, searchStore.maxPrice + 4] });
    }
  }, [ui, uiSet, searchStore.maxPrice]);

  return (
    <div className=" flex flex-row py-4 md:w-full fieldset-styles">
      <fieldset className="flex flex-col justify-center w-44 h-8 md:h-12 rounded-3xl md:rounded-md text-gray-400 self-center px-2 mx-2 fieldset-border">
        <legend className="hidden md:flex md:w-auto text-[0.7rem] ml-4 px-2 ">
          Sort By
        </legend>
        <div className="w-full md:mb-2 text-primary">
          <button
            ref={refSort}
            className="w-full flex flex-row justify-between border-none bg-transparent mb-1 md:mb-none"
            onClick={() => {
              const isOpening = !ui.showSortBy;
              uiSet({
                ...ui,
                // sortBy: searchStore.sortBy,
                showSortBy: isOpening,
                showRangePrice: false,
                showHighlights: false,
                showGuestRating: false,
              });
              if (isOpening) {
                pushLayerEvent('openSortByFilter', {});
              }
            }}
          >
            <p className="text-sm pt-1.5 px-1 md:pt-0 md:px-0">
              {searchStore.sortLabel}
            </p>
            <p className="text-sm pt-1.5 px-1 md:pt-0 md:px-0">
              {ui.showSortBy ? <UpOutlined /> : <DownOutlined />}
            </p>
          </button>

          <div
            ref={refSort}
            className={`overflow-hidden  ${
              ui.showSortBy
                ? `bg-white absolute ${
                    window.scrollY > 120 ? ' md:top-[4.5rem]' : 'md:top-48'
                  } md:left-2 md:w-56 z-10 border border-gray-300 rounded-md`
                : ''
            } hidden md:flex md:flex-col items-center`}
          >
            <div
              className={`w-4/5 flex flex-col py-4 ${
                ui.showSortBy ? 'visible' : 'hidden'
              } transition delay-300 ease-in-out`}
            >
              <Radio.Group onChange={handleChangeSortBy} value={ui.sortBy}>
                <Space direction="vertical">
                  <Radio style={{ margin: '0.5rem 0' }} value={1}>
                    Best match
                  </Radio>
                  <Radio style={{ margin: '0.5rem 0' }} value={2}>
                    Price (low to high)
                  </Radio>
                  <Radio style={{ margin: '0.5rem 0' }} value={3}>
                    Price (high to low)
                  </Radio>
                  <Radio style={{ margin: '0.5rem 0' }} value={7}>
                    Shuttle Frequency
                  </Radio>
                  <Radio style={{ margin: '0.5rem 0' }} value={4}>
                    Best Rating
                  </Radio>
                  <Radio style={{ margin: '0.5rem 0' }} value={5}>
                    Distance
                  </Radio>
                  <Radio style={{ margin: '0.5rem 0' }} value={6}>
                    A-Z
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
            <div>
              <Drawer
                className="md:hidden filter-fields-drawer drawer-sort-by"
                title="Sort by"
                height="auto"
                placement={'bottom'}
                onClose={onCloseSortBy}
                visible={ui.showSortBy}
              >
                <Radio.Group onChange={handleChangeSortBy} value={ui.sortBy}>
                  <Space direction="vertical">
                    <Radio
                      style={{ margin: '0.5rem 0', fontSize: '16px' }}
                      value={1}
                    >
                      Best match
                    </Radio>
                    <Radio
                      style={{ margin: '0.5rem 0', fontSize: '16px' }}
                      value={2}
                    >
                      Price (low to high)
                    </Radio>
                    <Radio
                      style={{ margin: '0.5rem 0', fontSize: '16px' }}
                      value={3}
                    >
                      Price (high to low)
                    </Radio>
                    <Radio
                      style={{ margin: '0.5rem 0', fontSize: '16px' }}
                      value={7}
                    >
                      Shuttle Frequency
                    </Radio>
                    <Radio
                      style={{ margin: '0.5rem 0', fontSize: '16px' }}
                      value={4}
                    >
                      Best Rating
                    </Radio>
                    <Radio
                      style={{ margin: '0.5rem 0', fontSize: '16px' }}
                      value={5}
                    >
                      Distance
                    </Radio>
                    <Radio
                      style={{ margin: '0.5rem 0', fontSize: '16px' }}
                      value={6}
                    >
                      A-Z
                    </Radio>
                    <Button
                      type="primary"
                      size="large"
                      className="h-10 w-72 rounded-full text-base font-bold my-2"
                      onClick={applyChangeSortBy}
                    >
                      Apply
                    </Button>
                  </Space>
                </Radio.Group>
              </Drawer>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset className="flex flex-col justify-center w-28 md:w-36 h-8 md:h-12 rounded-3xl md:rounded-md text-gray-400 self-center px-2 mx-2 fieldset-border">
        <legend className="hidden md:flex md:w-auto text-[0.7rem] ml-4 px-2">
          Price Range
        </legend>
        <div className="w-full md:mb-2">
          <button
            ref={refRangePrice}
            className="w-full flex flex-row justify-between border-none bg-transparent text-primary mb-1 md:mb-none"
            onClick={() =>
              uiSet({
                ...ui,
                showRangePrice: !ui.showRangePrice,
                showSortBy: false,
                showHighlights: false,
                showGuestRating: false,
              })
            }
          >
            <p className="text-sm pt-1.5 px-1 md:pt-0 md:px-0">
              {searchStore.filterLabelPriceRange}
            </p>
            <p className="text-sm pt-1.5 px-1 md:pt-0 md:px-0">
              {ui.showRangePrice ? <UpOutlined /> : <DownOutlined />}
            </p>
          </button>

          <div
            ref={refRangePrice}
            className={`overflow-hidden ${
              ui.showRangePrice
                ? `bg-white h-60 md:max-h-48 absolute ${
                    window.scrollY > 120 ? ' md:top-[4.5rem]' : 'md:top-48'
                  } md:left-[12.5rem] md:w-[22rem] z-10 border border-gray-300 rounded-md`
                : ''
            } hidden md:flex md:flex-col items-center `}
          >
            <div
              className={`w-4/5 flex flex-col py-4 ${
                ui.showRangePrice ? 'visible' : 'hidden'
              } transition delay-300 ease-in-out filter-fields-slider`}
            >
              <span className="text-base text-center my-3">
                Average price per day
              </span>
              <span className="text-sm text-center mb-10">
                <DollarCircleFilled className="text-primary h-auto font-bold text-base mr-1" />
                Cheap Rate is ${Number(searchStore.minPrice).toFixed(2)}
                <Divider className="bg-primary" type="vertical" /> Standard is $
                {(Number(searchStore.maxPrice) + 2).toFixed(2)}
              </span>
              <div>
                {ui.showRangePrice && (
                  <Slider
                    range={{ draggableTrack: true }}
                    max={Number(Math.ceil(searchStore.maxPrice).toFixed(0))}
                    min={0}
                    value={ui.rangePrice}
                    onChange={handleChangePriceRange}
                    tooltipVisible={ui.showRangePrice}
                    tipFormatter={(value) => <span>${value}</span>}
                  />
                )}
              </div>
            </div>
            <Drawer
              className="md:hidden filter-fields-drawer drawer-price"
              title="Price"
              height="auto"
              placement={'bottom'}
              onClose={onClosePriceRange}
              visible={ui.showRangePrice}
            >
              <div className="flex flex-col">
                <span className="text-base text-center">Average price</span>
                <span className="text-sm text-center mt-4 mb-10">
                  <DollarCircleFilled className="text-primary h-auto font-bold text-base mr-1" />
                  Cheap Rate is ${Number(searchStore.minPrice).toFixed(2)}
                  <Divider className="bg-primary" type="vertical" /> Standard is
                  ${(Number(searchStore.minPrice) + 2).toFixed(2)}
                </span>
              </div>
              <div className="filter-fields-slider">
                <Slider
                  range={{ draggableTrack: true }}
                  max={Number(Math.ceil(searchStore.maxPrice).toFixed(0))}
                  min={0}
                  value={ui.rangePrice}
                  onChange={handleChangePriceRange}
                  tooltipVisible={ui.showRangePrice}
                  tipFormatter={(value) => <span>${value}</span>}
                />
              </div>
              <Button
                type="primary"
                size="large"
                className="h-10 w-72 rounded-full text-base font-bold mb-2 mt-4"
                onClick={applyChangePriceRange}
              >
                Apply
              </Button>
            </Drawer>
          </div>
        </div>
      </fieldset>
      <fieldset className="flex flex-col justify-center w-32 md:w-36 h-8 md:h-12 rounded-3xl md:rounded-md text-gray-400 self-center px-2 mx-2 fieldset-border">
        <legend className="hidden md:flex md:w-auto text-[0.7rem] ml-4 px-2 ">
          Guest ratings
        </legend>
        <div className="w-full md:mb-2">
          <button
            ref={refGuest}
            className="w-full flex flex-row justify-between border-none bg-transparent text-primary mb-1 md:mb-none"
            onClick={() =>
              uiSet({
                ...ui,
                showGuestRating: !ui.showGuestRating,
                showSortBy: false,
                showRangePrice: false,
                showHighlights: false,
              })
            }
          >
            <p className="text-sm pt-1.5 px-1 md:pt-0 md:px-0">
              {searchStore.filterLabelRating}
            </p>
            <p className="text-sm pt-1.5 px-1 md:pt-0 md:px-0">
              {ui.showGuestRating ? <UpOutlined /> : <DownOutlined />}
            </p>
          </button>

          <div
            ref={refGuest}
            className={`overflow-hidden ${
              ui.showGuestRating
                ? `bg-white h-[19rem] md:max-h-[13.5rem] absolute ${
                    window.scrollY > 120 ? ' md:top-[4.5rem]' : 'md:top-48'
                  } md:left-[22.5rem] md:w-56 z-50 border border-gray-300 rounded-md`
                : ''
            } hidden md:flex md:flex-col items-center`}
          >
            <div
              className={`w-4/5 flex flex-col py-4 ${
                ui.showGuestRating ? 'visible' : 'hidden'
              } transition delay-300 ease-in-out`}
            >
              <h1 className="md:hidden text-base font-bold text-center">
                Guest Rating
              </h1>
              <Radio.Group
                onChange={handleChangeGuestRatings}
                value={ui.guestRating}
              >
                <Space direction="vertical">
                  <Radio style={{ margin: '0.5rem 0' }} value={1}>
                    Any guest rating
                  </Radio>
                </Space>
                <Divider className="!mt-1 !mb-1" />
                <Space direction="vertical">
                  <Radio style={{ margin: '0.5rem 0' }} value={2}>
                    <p>
                      4.5+
                      <span className="ml-1 text-green-900">Excellent!</span>
                    </p>
                  </Radio>
                  <Radio style={{ margin: '0.5rem 0' }} value={3}>
                    <p>
                      4.0+
                      <span className="ml-1 text-green-500">Very Good!</span>
                    </p>
                  </Radio>
                  <Radio style={{ margin: '0.5rem 0' }} value={4}>
                    <p>
                      3.5+
                      <span className="ml-1 text-lime-500">Good!</span>
                    </p>
                  </Radio>
                  <Button
                    type="primary"
                    size="large"
                    className="w-72 md:hidden my-2"
                    onClick={applyChangeGuestRatings}
                  >
                    Apply
                  </Button>
                </Space>
              </Radio.Group>
            </div>
            <Drawer
              className="md:hidden filter-fields-drawer drawer-guest-rating"
              title="Guest Rating"
              height="auto"
              placement={'bottom'}
              onClose={onCloseGuestRatings}
              visible={ui.showGuestRating}
            >
              <Radio.Group
                onChange={handleChangeGuestRatings}
                value={ui.guestRating}
              >
                <Space direction="vertical">
                  <Radio style={{ margin: '0.5rem 0' }} value={1}>
                    Any guest rating
                  </Radio>
                </Space>
                <Divider className="!mt-1 !mb-1" />
                <Space direction="vertical">
                  <Radio style={{ margin: '0.5rem 0' }} value={2}>
                    <p>
                      4.5+
                      <span className="ml-1 text-green-900">Excellent!</span>
                    </p>
                  </Radio>
                  <Radio style={{ margin: '0.5rem 0' }} value={3}>
                    <p>
                      4.0+
                      <span className="ml-1 text-green-500">Very Good!</span>
                    </p>
                  </Radio>
                  <Radio style={{ margin: '0.5rem 0' }} value={4}>
                    <p>
                      3.5+
                      <span className="ml-1 text-lime-500">Good!</span>
                    </p>
                  </Radio>
                  <Button
                    type="primary"
                    size="large"
                    className="w-72 md:hidden my-2"
                    onClick={applyChangeGuestRatings}
                  >
                    Apply
                  </Button>
                </Space>
              </Radio.Group>
            </Drawer>
          </div>
        </div>
      </fieldset>

      <fieldset className="flex flex-col justify-center w-32 md:w-36 h-8 md:h-12 rounded-3xl md:rounded-md text-gray-400 self-center px-2 mx-2 fieldset-border">
        <legend className="hidden md:flex md:w-auto text-[0.7rem] ml-4 px-2 ">
          Highlights
        </legend>
        <div className="w-full md:mb-2">
          <button
            ref={refHighlights}
            className="w-full flex flex-row justify-between border-none bg-transparent text-primary mb-1 md:mb-none"
            onClick={() => {
              const isOpening = !ui.showHighlights;
              uiSet({
                ...ui,
                showHighlights: isOpening,
                showSortBy: false,
                showRangePrice: false,
                showGuestRating: false,
              });
              if (isOpening) {
                pushLayerEvent('openHighlightsFilter', {});
              }
            }}
          >
            <p className="text-sm pt-1.5 px-1 md:pt-0 md:px-0">
              {ui.highlights.length === 0
                ? 'Highlights'
                : ui.highlights.length === 1
                ? `${ui.highlights.length} highlight`
                : `${ui.highlights.length} highlights`}
            </p>
            <p className="text-sm pt-1.5 px-1 md:pt-0 md:px-0">
              {ui.showHighlights ? <UpOutlined /> : <DownOutlined />}
            </p>
          </button>

          <div
            ref={refHighlights}
            className={`overflow-hidden ${
              ui.showHighlights
                ? `bg-white absolute ${
                    window.scrollY > 120 ? ' md:top-[4.5rem]' : 'md:top-48'
                  } w-full lg:w-96 md:w-[22rem] sm:w-[97.8%] right-[0.5px] md:right-auto z-10 border border-gray-300 rounded-md`
                : ''
            }  hidden md:flex md:flex-col  items-center  `}
          >
            <div
              className={`w-4/5 flex flex-col py-4 ${
                ui.showHighlights ? 'visible' : 'hidden'
              } `}
            >
              <Checkbox.Group
                style={{ width: '100%' }}
                onChange={handleChangeHighlights}
                value={ui.highlights}
              >
                <Col>
                  <Row>
                    <Checkbox
                      value="facility_branded"
                      style={{ margin: '0.5rem 0' }}
                    >
                      Branded
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox
                      value="facility_unbranded"
                      style={{ margin: '0.5rem 0' }}
                    >
                      Unbranded
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox
                      value="facility_covered"
                      style={{ margin: '0.5rem 0' }}
                    >
                      Covered
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox
                      value="facility_uncovered"
                      style={{ margin: '0.5rem 0' }}
                    >
                      Uncovered
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox
                      value="facility_accessibility"
                      style={{ margin: '0.5rem 0' }}
                    >
                      Wheelchair Accessible
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox
                      value="facility_free_cancellation"
                      style={{ margin: '0.5rem 0' }}
                    >
                      Free Cancellation
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox
                      value="facility_eletric_car"
                      style={{ margin: '0.5rem 0' }}
                    >
                      EV Charging
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox
                      value="facility_free_shuttles"
                      style={{ margin: '0.5rem 0' }}
                    >
                      Airport Shuttle
                    </Checkbox>
                  </Row>
                </Col>
              </Checkbox.Group>
            </div>
            <Drawer
              className="md:hidden filter-fields-drawer drawer-highlights"
              title="Highlights"
              height="auto"
              placement={'bottom'}
              onClose={onCloseHighlights}
              visible={ui.showHighlights}
            >
              <Checkbox.Group
                style={{ width: '100%' }}
                onChange={handleChangeHighlights}
                value={ui.highlights}
              >
                <Col>
                  <Row>
                    <Checkbox
                      value="facility_branded"
                      style={{ margin: '0.5rem 0' }}
                    >
                      Branded
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox
                      value="facility_unbranded"
                      style={{ margin: '0.5rem 0' }}
                    >
                      Unbranded
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox
                      value="facility_covered"
                      style={{ margin: '0.5rem 0' }}
                    >
                      Covered
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox
                      value="facility_uncovered"
                      style={{ margin: '0.5rem 0' }}
                    >
                      Uncovered
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox
                      value="facility_accessibility"
                      style={{ margin: '0.5rem 0' }}
                    >
                      Wheelchair Accessible
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox
                      value="facility_free_cancellation"
                      style={{ margin: '0.5rem 0' }}
                    >
                      Free Cancellation
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox
                      value="facility_eletric_car"
                      style={{ margin: '0.5rem 0' }}
                    >
                      EV Charging
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox
                      value="facility_free_shuttles"
                      style={{ margin: '0.5rem 0' }}
                    >
                      Airport Shuttle
                    </Checkbox>
                  </Row>
                  <Button
                    type="primary"
                    size="large"
                    className="w-72 md:hidden my-3"
                    onClick={applyChangeHighlights}
                  >
                    Apply
                  </Button>
                </Col>
              </Checkbox.Group>
            </Drawer>
          </div>
        </div>
      </fieldset>
      {searchStore.isFilterModified ? (
        <Button
          className="border-none bg-transparent text-primary mb-1 md:mt-2"
          onClick={handleResetFilter}
        >
          <UndoOutlined className="rotate-90" /> Reset
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
}

export default observer(FilterFields);
